import { RouteRecordRaw } from "vue-router";
import Home from "../Layout.vue";
import Login from "../views/Login.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/system/index",
    children: [
      //系统管理模块
      {
        path: "/system/index",
        name: "Index",
        component: () => import("@/views/system-setting/index.vue")
      },
      {
        path: "/system/version",
        name: "Version",
        component: () => import("@/views/system-setting/VersionManage.vue")
      },

      //用户管理模块
      {
        path: "/users/permission",
        name: "Permission",
        component: () => import("@/views/user-center/PermissionManage.vue")
      },
      {
        path: "/users/role",
        name: "Role",
        component: () => import("@/views/user-center/RoleManage.vue")
      },
      {
        path: "/users/user",
        name: "User",
        component: () => import("@/views/user-center/UserManage.vue")
      },

      {
        path: "/users/permission",
        name: "Permission",
        component: () => import("@/views/user-center/PermissionManage.vue")
      },

      //设备管理
      {
        path: "/devices/deviceList",
        name: "DeviceList",
        component: () => import("@/views/device-manage/DeviceList.vue")
      },

      //体温贴设备管理
      {
        path: "/devices/ttDeviceList",
        name: "TtDeviceList",
        component: ()=>import("@/views/device-manage/TtDeviceList.vue")
      },
      
      //体温贴设备维护
      {
        path: "/devices/ttDeviceMaintain",
        name: "TtDeviceMaintain",
        component: ()=>import("@/views/device-manage/TtDeviceMaintain.vue")
      },

      /***
       *  设备类型
       */
      {
        path: "/devices/deviceType",
        name: "DeviceType",
        component: ()=>import("@/views/device-manage/DeviceTypeList.vue")
      },  

      /***
       * 设备评论
       */
      {
        path: "/devices/deviceComment",
        name: "DeviceComment",
        component: ()=>import("@/views/device-manage/DeviceCommentList.vue")
      },


      //血压计数据管理
      {
        path: "/datas/dataList",
        name: "DataList",
        component: () => import("@/views/data-manage/DataList.vue")
      },

      //体温帖数据管理
      {
        path: "/datas/ttDataList",
        name: "TtDataList",
        component: ()=>import("@/views/data-manage/TtDataList.vue")
      },
      //短信管理
      {
        path: "/messages/messageList",
        name: "MessageList",
        component: () => import("@/views/message-manage/MessageList.vue")
      },
  
      //用户反馈
      {
        path: "/system/feedback",
        name: "FeedbackManage",
        component: ()=>import("@/views/system-setting/FeedbackManage.vue")
      },

      //版本管理
      {
        path: "/system/version",
        name: "VersionManage",
        component:()=> import("@/views/system-setting/VersionManage.vue")
      },

      //获取版本详情
      { 
        path: "/system/version/detail",
        name: "VersionDetail",
        component: ()=> import("@/views/system-setting/VersionDetail.vue")
      },
      
      //轮播图管理
      {
        path: "/system/banner",
        name: "BannerSystem",
        component: ()=>import("@/views/system-setting/BannerManage.vue")
      },

      //隐私策略
      {
        path: "/system/privacy",
        name: "PrivacyProtocol",
        component: ()=>import("@/views/system-setting/PrivacyProtocol.vue")
      },
      //常见问题
      {
        path: "/system/question",
        name: "CommonQuestion",
        component: ()=>import("@/views/system-setting/CommonQuestion.vue")
      }

      //暂无功能
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/totalDataVisual",
    name: "TotalDataVisual",
    component: ()=>import("@/views/data-visual/TotalDataVisual.vue")
  },
  {
    path: "/personalDataVisual",
    name: "PersonalDataVisual",
    component: ()=>import("@/views/data-visual/PersonalDataVisual.vue")
  }
];

export { routes };
