import axios from "axios";

/***
 * 获取用户分页列表
 */
const context = "/ucenter";
export function getUserPagination(data: any) {
  return axios.get(`${context}/sysUser/pagination`, { params: data });
}

/***
 * 新增用户
 */
export function addUser(data: any) {
  return axios.post(`${context}/sysUser`, data);
}
/**
 * 修改用户
 */
export function updateUser(data: any) {
  return axios.put(`${context}/sysUser`, data);
}

/***
 * 删除用户
 */
export function removeUser(data: any) {
  return axios.post(`${context}/sysUser/batchRemove`, data);
}

/***
 * 获取角色列表
 */
export function getRolePagination(data: any) {
  return axios.get(`${context}/sysRole/pagination`, { params: data });
}

/***
 * 获取所有角色
 */
export function getAllRoleList(data: any) {
  return axios.get(`${context}/sysRole/list`, { params: data });
}

/***
 * 获取权限树
 */
export function getPermissionTree(data: any) {
  return axios.get(`${context}/sysMenu/list`, { params: data });
}

/***
 * 新增角色
 */
export function addRole(data: any) {
  return axios.post(`${context}/sysRole`, data);
}
/**
 * 修改角色
 */
export function updateRole(data: any) {
  return axios.put(`${context}/sysRole`, data);
}

/***
 * 删除角色
 */
export function removeRole(data: any) {
  return axios.post(`${context}/sysRole/batchRemove`, data);
}

/**
 * 更新密码
 */
export function updateUserPassword(data: any) {
  return axios.put(`${context}/sysUser/password`, data);
}


/***
 * 获取当前用户的权限列表
 */
export function getCurUserPermissionList(data:any){
  return axios.get(`${context}/sysUser/curUserPermissionList`, data);
}


