import { NavItem } from "../../model/NavItem";
import { Ref, ref } from "vue";
import { Router } from "vue-router";
class LayoutService {
  roleName: Ref<string | null> = ref(null);
  avatarUrl: Ref<string | null> = ref(null);
  curNavIndex = ref(0);
  curMenuList = ref<Array<NavItem>>([]);
  asideMenuMap = {
    systemsetting: [
      new NavItem(1, "首页", "iconfont icon-shouye", "/system/index", "system:index:menu", true, []),
      new NavItem(
        2,
        "用户管理",
        "iconfont icon-yonghuguanli",
        "",
        "users:user:folder",
        true,
        [
          new NavItem(21, "角色列表", "icon-yingjian", "/users/role", "users:role:menu", true, []),
          new NavItem(22, "用户列表", "iconfont icon-yonghu", "/users/user", "users:user:menu", true,[]),
          new NavItem(23, "权限列表", "iconfont icon-yonghu", "/users/permission", "users:permission:menu", true,[])
        ]
      ),
      new NavItem(3, "轮播图管理", "iconfont icon-yingjian", "", "banners:banner:folder", true,[
        new NavItem(31, "轮播图列表", "iconfont icon-yingjian", "", "banners:banner:menu",true, [
        ])
      ]),

      new NavItem(4, "设备管理", "iconfont icon-yingjian", "", "devices:device:folder", true,[
        new NavItem(40, "设备SPU列表", "icon-yingjian", "/devices/deviceType", "devices:device:type",true, []),
        new NavItem(44, "设备SPU评论审核", "icon-yingjian", "/devices/deviceComment", "devices:device:comment",true, []),
        new NavItem(41, "血压计设备列表", "icon-yingjian", "/devices/deviceList", "devices:device:menu",true, []),
        // new NavItem(42, "体温贴设备维护", "icon-yingjian", "/devices/ttDeviceMaintain", "devices:device:menu",true, []),
        new NavItem(43, "体温帖设备列表", "icon-yingjian", "/devices/ttDeviceList", "devices:device:menu",true, [])
      ]),
      new NavItem(5, "数据管理", "iconfont icon-yingjian", "", "datas:data:folder", true,[
        new NavItem(51, "血压数据列表", "icon-yingjian", "/datas/dataList", "datas:data:menu",true, []),
        new NavItem(52, "体温数据列表", "icon-yingjian", "/datas/ttDataList", "datas:data:menu",true, []),
      ]),
      new NavItem(
        6,
        "短信管理",
        "iconfont icon-duanxinguanli",
        "/system/phoneMessage",
        "messages:message:folder",true,
        [
          new NavItem(
            61,
            "短信列表",
            "icon-yingjian",
            "/messages/messageList",
            "messages:textmessage:menu",true,
            []
          ),
        ]
      ),
      new NavItem(
        7,
        "系统设置",
        "iconfont icon-xitong",
        "/system/phoneMessage",
        "systems:system:folder",
        true,
        [
          new NavItem(
            71,
            "轮播图列表",
            "icon-yingjian",
            "/system/banner",
            "systems:banner:file",
            true,
            []
          ),
          new NavItem(
            72,
            "用户反馈",
            "icon-yingjian",
            "/system/feedback",
            "systems:feedback:file",true,
            []
          ),
          new NavItem(
            73,
            "版本管理",
            "icon-yingjian",
            "/system/version",
            "systems:version:file",true,
            []
          ),
          new NavItem(
            74,
            "版本详情",
            "icon-yingjian",
            "/system/version/detail",
            "systems:version:detail:file",
             true,
            []
          )
        ]
      )
    ]
  };

  constructor() {
    this.avatarUrl.value =
      "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png";
    this.roleName.value = "超级管理员";
    this.curMenuList.value = this.asideMenuMap["systemsetting"];
  }

  useService() {
    console.log("layout.service: ", this.curMenuList.value)
    return this;
  }

  //修改当前导航索引
  changeNavIndex($router: Router, index: number): void {
    this.curNavIndex.value = index;

    switch (index) {
      case 0:
        this.curMenuList.value = this.asideMenuMap["systemsetting"];
        break;
    }

    //判断是否有 selected 项，如果有就不做任何处理
    const existedSeelctedItem = this.curMenuList.value.find(
      item => item.selected
    );
    if (existedSeelctedItem) {
      $router.push(existedSeelctedItem.path + "");
      return;
    }

    console.log("existedSeelctedItem: ", existedSeelctedItem);
    const defaultItem = this.curMenuList.value[0];
    if (!defaultItem) {
      $router.push("/notFound");
      return;
    }
    defaultItem.selected = true;
    $router.push(defaultItem.path + "");
  }

  //跳转页面
  changePage($router: Router, item: NavItem): void {
    let list = this.curMenuList.value;
    list = list.map(item => {
      item.selected = false;
      return item;
    });
    item.selected = true;
    $router.push(item.path + "");
  }
}

const layoutService = new LayoutService();
export default layoutService;
