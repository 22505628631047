export class Account {
  type: number;
  username: string;
  passwd?: string;
  code?: string;

  constructor() {
    this.type = 1;
    this.username = "";
  }

  getType(): number {
    return this.type;
  }

  setType(type: number): void {
    this.type = type;
  }

  getUsername(): string {
    return this.username;
  }
  setUsername(username: string): void {
    this.username = username;
    return;
  }
  getPasswd(): string | undefined {
    return this.passwd;
  }
  setPasswd(passwd: string): void {
    this.passwd = passwd;
  }
  getCode(): string | undefined {
    return this.code;
  }
  setCode(code: string | undefined): void {
    this.code = code;
  }
}
