type ValidationFunc = (rule: any, value: any, callback: any) => void;
export class Validation {
  trigger: string;
  validator: ValidationFunc;

  constructor(trigger: string, validator: ValidationFunc) {
    this.trigger = trigger;
    this.validator = validator;
  }
}
