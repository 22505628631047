import axios, { AxiosResponse } from "axios";
import {  useRouter } from 'vue-router';

//设置 base url。
// axios.defaults.baseURL = "/api";

const whiteList = [];

//配置请求拦截器
axios.interceptors.request.use(config => {
  //为当前请求配置 token 认证字段
  console.log("url: ", config.url);
  if( "/ucenter/auth" != config.url ){
    const token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers.token = token;
    }
  }
  return config;
});

//配置响应拦截器
axios.interceptors.response.use(
  function (response:any) {

    const res = response.data;
    //针对处理阿里云oss上传连接特殊处理
    const requestUrl = "https://kfsy-mengze.oss-cn-beijing.aliyuncs.com/"
    console.log("response.request.responseURL: ",response.request.responseURL)
    if( response.request.responseURL == requestUrl || response.request.responseURL.indexOf("/deviceservice/device/twt/downloadReportData" )>-1 ){
        return res;
    }

    //内码错误处理
    if (res.code != 200) {
      if( res.code == 401 ){
          setTimeout(()=>{
            const $router = useRouter();
            $router.replace("/login");
          }, 500)
        return;
      }
      console.log("error", res);
      throw Error(res.msg);
    }
    console.log("right1", res.data);
    return res.data;
  },
  error => {
    throw Error(error.error || error);
    console.log("error: ", error);
  }
);
