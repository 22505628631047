import { AccountValidation } from "../../model/AccountValidation";
import { Account } from "../../model/Account";
import { ref } from "vue";
import { FormInstance } from "element-plus";
import { ElMessage } from 'element-plus'
import { login } from '../../../api/login.api';
import { useRouter } from 'vue-router';

export class LoginService {
    //表单验证对象
    rules=new AccountValidation();
    //表单对象
    ruleForm=ref(new Account());
    //form表单 ui 的句柄
    ruleFormRef=ref<FormInstance>();
    //只读
    readonly=ref(true);
    loginLoading=ref(false); 
    $router = useRouter()

    constructor(){
        this.ruleForm.value.setUsername("");
        this.ruleForm.value.setPasswd("")
    }

    useService(){
        return {
            ...this
        }
    }

    showUserInput( value:boolean ){
       this.readonly.value = false;
    }


    submitForm(){

        console.log("this.router: ", this.$router)
        this.loginLoading.value = true;
        const self = this;
        const formData = {
            userName: this.ruleForm.value.getUsername(),
            password: this.ruleForm.value.getPasswd()
        }
        login( formData ).then( function( res:any ){

            self.loginLoading.value = false;
            const token = res.token;
            const userInfo = res.userInfo;
            localStorage.setItem("TOKEN", token );
            localStorage.setItem("EXPIRE_TIME", new Date().getTime()+90*24*3600*1000+"");
            localStorage.setItem("USER_INFO", JSON.stringify(userInfo) );
            localStorage.setItem("ACTIVE_INDEX", '');
            ElMessage({
                message: '登录成功',
                type: 'success' 
            })
            setTimeout(()=>{
                self.$router.replace("/");
            }, 1000)

        }).catch( error=>{
            this.loginLoading.value = false;
            console.log("error: ", error)
            ElMessage({
                message: error,
                type: 'error'
            })
        } );
    }

}