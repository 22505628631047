import { Validation } from "./Validation";
export class AccountValidation {
  username: Array<Validation>;
  passwd: Array<Validation>;
  code: Array<Validation>;

  constructor() {
    this.username = [
      new Validation("blur", (rule: any, value: any, callback: any): void => {
        console.log("....");
      })
    ];
    this.passwd = [
      new Validation("blur", (rule: any, value: any, callback: any): void => {
        console.log("....");
      })
    ];
    this.code = [
      new Validation("blur", (rule: any, value: any, callback: any): void => {
        console.log("....");
      })
    ];
  }
}
