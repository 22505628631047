<template>
  <div >
      <Editor :init="myTinyInit"  v-model="content" />
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from 'vue'
import Editor from '@tinymce/tinymce-vue';

import 'tinymce/tinymce'
import 'tinymce/models/dom'
import 'tinymce/icons/default'
import 'tinymce/themes/silver/theme'
// Skins
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import content_style from 'tinymce/skins/content/dark/content.min.css?inline' //将内容存入content_style

// Plugins 组件根据init中调用情况自行加载
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/autoresize'

const props = defineProps(["modelValue", "height"]);
const $emit = defineEmits(["update:modelValue"]);
const content = ref("");
watch( ()=> content.value, (newValue)=>{
  console.log(newValue)
  $emit("update:modelValue", newValue);
});



const myTinyInit = ref({
  skin:false,
  content_css: '',
  content_style: content_style, //将导入的css内容送给content_style参数使用
  //编辑器宽度，在tinymce版本6中要将toolbar_mode设置为wrap才生效
  language: "zh_CN", //语言
  height:  props.height || 500, //编辑器高度
  branding: false, //不显示logo
  menubar: false, 
  resize: false, 
  toolbar_mode: 'wrap',
  skeletonScreen: true, //编辑器懒加载，但设置后好像不生效
  placeholder: '请输入内容',
  plugins: ['lists link image table paste help wordcount'], //需要的插件，可参考官方文档引入自己需要的插件
  toolbar: 'undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table image | help', //工具栏显示工具，可参考官方文档引入自己需要的工具
  fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 26px 36px 48px 56px',
  //自定义图片上传方法（tinymce版本5）
  images_upload_handler:async (blobInfo, success, failure)=> {
      var formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      // const res = await tinymceImageUpload(formData); //tinymceImageUpload为封装的图片上传方法，可换成自己的方法
      // if (res.code === 1) {
      //     success(`/image_manipulation/${res.data.filePath}`); //成功之后，返回图片路径，此处/image_manipulation/为图片路径前缀，具体情况根据后端返回的结果来
      // } else {
      //     failure('Image upload failed due to a XHR Transport error. Code: ' + res.msg);
      // }
  },
})

</script>