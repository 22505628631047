export class NavItem {
  id: number;
  name: string;
  icon: string | null;
  path: string | null;
  selected: boolean;
  perms: string;
  show: boolean;
  children: Array<NavItem> | null;

  constructor(id: number, 
          name: string, 
          icon: string, 
          path: string, 
          perms: string, 
          show: boolean, 
          children: Array<NavItem> | null) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.path = path;
    this.perms = perms;
    this.children = children;
    this.selected = false;
    this.show = show;
    console.log(this.name + ' :: '+this.show)
  }
}
