import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterOptions,
  Router,
  RouterHistory
} from "vue-router";
import { routes } from "./routes";

class RouterUtils {
  routes: Array<RouteRecordRaw>;

  constructor(routes: Array<RouteRecordRaw>) {
    this.routes = routes;
  }

  startCreateRouter(
    history: RouterHistory = createWebHistory(process.env.BASE_URL)
  ): Router {
    
    const options: RouterOptions = {
      history,
      routes: this.routes,
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { top: 0 }
        }
      },
    };
    return createRouter(options);
  }
}

export default new RouterUtils(routes).startCreateRouter();
