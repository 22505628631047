import { createApp, getCurrentInstance } from "vue";
import App from "./App.vue";
import router from "./router";
import "./router/permission";
import "./assets/font/font.css";
import './config/http.config';
import { createPinia } from "pinia";
// 富文本组件
import Tinymce from "@/components/tinymce/Tinymce.vue";

/***
 * 完整导入 element-plus
 */
//引入element-plus
import ElementPlus from "element-plus";
//引入element-plus相关样式
import "element-plus/dist/index.css";

const app = createApp(App);
app.component("Tinymce", Tinymce);
app.config.globalProperties.$router = router;
console.log("getCurrentInstance:"+getCurrentInstance());
//挂在 vue-router
app.use(router);
app.use(createPinia());
//挂载 element-plus
app.use(ElementPlus);
app.mount("#app");
